/*Title Normal*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Georgian:wght@800&display=swap');

/*Description Normal*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/*Title Enhanced*/
@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');

/*Description Enhanced*/
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root{
        --light-background: #DBD0C0;
        --light-backgroundFade: rgba(219,208,192, 0.7);
        --dark-background: #1a202c;
        --dark-backgroundFade: rgba(26,32,44, 0.7);
        --light-foreground: #FAEEE0;
        --dark-foreground: #718096;
        --light-textColor: #000000;
        --dark-textColor: #FFFFFF;

        --button-color: #000000;
        --button-shadow: #6d6d6d;
    }

    body {
        @apply bg-background dark:bg-backgroundDark 
    }
}